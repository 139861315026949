import React, { lazy, Suspense } from "react"
import { view } from "@risingstack/react-easy-state"
import { Route, Switch, useLocation, useHistory } from "react-router-dom"
import queryString from "query-string"

import * as AuthHelper from "helpers/auth.helper"

// Pages
const Error404Page = lazy(() => import("pages/Error404"))
const LoginPage = lazy(() => import("pages/Login"))
const RegisterPage = lazy(() => import("pages/Register"))
const DashboardPage = lazy(() => import("pages/DashboardMobile"))

const FullPageLoading = () => {
  return <div className="h-screen w-screen">
    <div className="flex h-full w-full items-center justify-center bg-indigo-100 text-primary">
      <svg className="animate-spin -ml-1 mr-3 h-5 w-5" style={{animationTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)"}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  </div>
}

const Routes = () => {
  const location = useLocation()
  const history = useHistory()
  const params = queryString.parse(location.search)
  const { token, ...otherParams } = params

  if (!!token) {
    AuthHelper.setToken(token)

    const redirectUrl = [location.pathname, queryString.stringify(otherParams)]
      .filter((e) => !!e)
      .join("?")

    history.replace(redirectUrl)
    return <div><FullPageLoading/></div>
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Suspense fallback={<FullPageLoading/>}>
        <Route path="/">
          <Switch>
            <Route path="/channels" component={DashboardPage}/>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/register" component={RegisterPage} />
            <Route path="/" component={DashboardPage}/>
            <Route path="/" component={Error404Page} />
          </Switch>
        </Route>
      </Suspense>
    </div>
  )
}

export default view(Routes)
