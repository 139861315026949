import { fetchFromLocalStorage, storeInLocalStorage } from "store/localStorage"

const TOKEN_KEY = "token"

export const getToken = () => {
  return fetchFromLocalStorage({
    key: TOKEN_KEY,
  })
}

export const setToken = (token) => {
  return storeInLocalStorage({
    key: TOKEN_KEY,
    value: token,
  })
}

export const isLoggedIn = () => {
  const token = getToken()

  if (token) return true
  return false
}

export const logout = () => {
  storeInLocalStorage({ key: TOKEN_KEY, value: null })
  storeInLocalStorage({ key: "user", value: null })
}
