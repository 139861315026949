import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import { isMobile } from "react-device-detect"

import Routes from "routes"

import ErrorBoundary from "components/ErrorBoundary"

import "antd/dist/antd.css"
import "./assets/css/main.css"

import "./App.css"

function App() {
  return (
    <div className={isMobile ? "mobile" : ""}>
      <ErrorBoundary>
        <Router>
          <Routes />
        </Router>
      </ErrorBoundary>
    </div>
  )
}

export default view(App)
