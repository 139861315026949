const config = {
  site: {
    name: "Learner",
    companyName: "Learner",
    dashboardPath: "/",
    clientSupportEmail: "support@learner.com",
    footerLinks: [
      {
        label: "Contact",
        href: "/contact",
      },
      {
        label: "FAQ",
        href: "/faq",
      },
      {
        label: "Help",
        href: "/help",
      },
    ],
  },
}

export default config
